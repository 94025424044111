import Client from "../client/ClientAssetControl"
import version from "../version"
const resource = version + "/client"

function getSettings(params) {
  return Client("root_vehicle.service_path_view").get(`${version}/staff/vehicle-setting/list/`, {
    params
  })
}
function gets(params) {
  return Client("root_ac_client_vehicle.ac_client_vehicle_path_view").get(
    `${resource}/vehicle/list/`,
    { params }
  )
}
function getInternalServices() {
  return Client("root_service.service_path_view").get(`${version}/internal/internal-services/`)
}
function get(id) {
  return Client("root_ac_client_vehicle.ac_client_vehicle_path_detail").get(
    `${resource}/vehicle/retrieve/${id}/`
  )
}
function create(data) {
  return Client("root_ac_client_vehicle.ac_client_vehicle_path_create").post(
    `${resource}/vehicle/create/`,
    data
  )
}
function update(id, data) {
  return Client("root_ac_client_vehicle.ac_client_vehicle_path_update").put(
    `${resource}/vehicle/retrieve/${id}/`,
    data
  )
}
function updateStatusVehicle(id, data) {
  return Client("root_service.service_path_update").put(
    `${resource}/vehicle/retrieve/${id}/update-status/`,
    data
  )
}
function requestApproval(data, action, requestId) {
  return Client("root_service.service_path_create", undefined, action, requestId).post(
    `${resource}/vehicle/request-approval/`,
    data
  )
}

function remove(id) {
  return Client("root_ac_client_vehicle.ac_client_vehicle_path_delete").delete(
    `${resource}/vehicle/retrieve/${id}/`
  )
}
function exportVehicle(data) {
  return Client("root_ac_client_vehicle.ac_client_vehicle_path_export").post(
    `${resource}/vehicle/export/`,
    data
  )
}
function getLogs(id, params) {
  return Client("root_service.service_path_detail").get(
    `${resource}/vehicle/retrieve/${id}/operation-logs/`,
    { params }
  )
}

//Groups
function getVehicleGroups(params) {
  return Client("root_vehicle_group.vehicle_group_path_view").get(
    `${resource}/vehicle-group/list/`,
    {
      params
    }
  )
}
function getDetailVehicleGroup(id) {
  return Client("root_vehicle_group.vehicle_group_path_detail").get(
    `${resource}/vehicle-group/retrieve/${id}/`
  )
}
function createVehicleGroup(data) {
  return Client("root_vehicle_group.vehicle_group_path_create").post(
    `${resource}/vehicle-group/create/`,
    data
  )
}
function updateVehicleGroup(id, data) {
  return Client("root_vehicle_group.vehicle_group_path_update").put(
    `${resource}/vehicle-group/retrieve/${id}/`,
    data
  )
}
function removeVehicleGroup(id) {
  return Client("root_vehicle_group.vehicle_group_path_delete").delete(
    `${resource}/vehicle-group/retrieve/${id}/`
  )
}

//Depot
function getVehicleDepots(params) {
  return Client("root_vehicle_depot.vehicle_depot_path_view").get(
    `${resource}/vehicle-depot/list/`,
    {
      params
    }
  )
}
function getDetailVehicleDepot(id) {
  return Client("root_vehicle_depot.vehicle_depot_path_detail").get(
    `${resource}/vehicle-depot/retrieve/${id}/`
  )
}
function createVehicleDepot(data) {
  return Client("root_vehicle_depot.vehicle_depot_path_create").post(
    `${resource}/vehicle-depot/create/`,
    data
  )
}
function updateVehicleDepot(id, data) {
  return Client("root_vehicle_depot.vehicle_depot_path_update").put(
    `${resource}/vehicle-depot/retrieve/${id}/`,
    data
  )
}
function removeVehicleDepot(id) {
  return Client("root_vehicle_depot.vehicle_depot_path_delete").delete(
    `${resource}/vehicle-depot/retrieve/${id}/`
  )
}
function getVehicleUsageImage(id, data) {
  return Client("root_service.service_path_create").post(
    `${resource}/vehicle/usage/${id}/image/`,
    data
  )
}
const api = {
  getSettings,
  gets,
  getInternalServices,
  get,
  create,
  update,
  updateStatusVehicle,
  requestApproval,
  remove,
  exportVehicle,
  getLogs,
  getVehicleGroups,
  getDetailVehicleGroup,
  createVehicleGroup,
  updateVehicleGroup,
  removeVehicleGroup,
  getVehicleDepots,
  getDetailVehicleDepot,
  createVehicleDepot,
  updateVehicleDepot,
  removeVehicleDepot,
  getVehicleUsageImage
}
export default api
